<template>
  <section class="main_container py-50">
    <div class="col align-center text-center">
      <h2>Contact us</h2>
      <form @submit.prevent="send" class="formContainer col gap-20">
        <div class="text-start">
          <input
            v-model="name"
            placeholder="Your Name"
            type="text"
            class="input"
            :class="{ error: nameError }"
          />
          <span class="red--text font-14" v-if="nameError">
            {{ nameError }}
          </span>
        </div>
        <div class="text-start">
          <input
            v-model="email"
            placeholder="Your Email"
            type="text"
            class="input"
            :class="{ error: emailError }"
          />
          <span class="red--text font-14" v-if="emailError">
            {{ emailError }}
          </span>
        </div>
        <div class="text-start">
          <textarea
            v-model="message"
            placeholder="Message"
            type="text"
            class="input"
            :class="{ error: message_nameError }"
          />
          <span class="red--text font-14" v-if="message_nameError">
            {{ message_nameError }}
          </span>
        </div>
        <button class="btn">SEND MESSAGE</button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      nameError: "",
      emailError: "",
      message_nameError: "",
    };
  },
  methods: {
    validateForm() {
      let required = "Required";
      let nameError =
        "Only alphanumeric characters, underscore, dot and space allowed, min length 3, max length 63";
      let emailError = "Not valid email address";
      let message_nameError =
        "Only alphanumeric characters, underscore and dot allowed, min length 3, max length 63";

      let reg = new RegExp("^[a-zA-Z0-9_. ]{3,63}$");
      let name = reg.test(this.name);
      reg = RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      let email = reg.test(this.email);
      reg = new RegExp("^[a-zA-Z0-9_.]{3,63}$");
      let message = reg.test(this.message);

      this.nameError = !name ? nameError : "";
      this.emailError = !email ? emailError : "";
      this.message_nameError = !message ? message_nameError : "";

      if (this.name.length == 0) this.nameError = required;
      if (this.email.length == 0) this.emailError = required;
      if (this.message.length == 0) this.message_nameError = required;

      return (
        this.nameError ||
        this.emailError ||
        this.messageError
      );
    },
    send() {
      if (this.validateForm()) return;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.input {
  border: 1px solid rgb(147, 147, 147);
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 15px;
  font-size: 20px;
  box-sizing: border-box;
}

.formContainer {
  max-width: 600px;
  width: 100%;
}

img {
  max-width: 250px;
}

.error {
  background-color: rgb(251, 236, 236);
  border: 1px solid red;
}
</style>