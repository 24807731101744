<template>
  <Contact />
</template>

<script>
import Contact from "@/components/contact/Contact.vue";
export default {
  components: {
    Contact,
  },
};
</script>

<style>
</style>